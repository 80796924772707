import { Box } from "@mui/material";
import React from "react";
import {
  Buttons,
  DrawerCTA,
  Gallery,
  Highlights,
  Price,
  TopDrawer,
  useActiveUnit,
} from ".";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import { getFocus, useAtom, useGet } from 'state/jotai'
import { get } from 'components/Favorites/Favorites.Utilities'
import { active_project, drawer_animation_done } from 'state/store.global'
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { _project } from "state/store.projects";

function UnitDrawer() {
  const unit        = useActiveUnit();
  const highlights = useUnitHighlight(unit);
  const unitId     = parseInt(unit.getId());
  const status     = unit.getStatus();
  const address    = unit.getAddress();
  const townAndZipcode = unit.getTownZipCode();
  const images     = unit.getImages();
  const isRent     = unit.isRent();
  const price      = unit.getPrice();
  const salestype = unit?.getSalesTypeName();
  const isDrawerAnimating = useAtom(drawer_animation_done)[0]
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];


  const filterHighlights = highlights.filter((highlight) => {
   
    if(highlight?.value )
      return true
    
    // if (salestype?.Value === "Lejlighed" && highlight.value   ) {
    //   return highlight.property !== "Uderum"
      
    // } else if (salestype?.Value === "Rækkehus" && highlight.value ) {
    //   return highlight.property !== "Etage_Text";
    // }
  });
  
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // pb: 5,
        backgroundColor: "#ffffff",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between"
      }}
    >
      <Box>
      <TopDrawer
        address={`${address}, ${townAndZipcode}`}
        statusText={status.label}
        backgroundColor={status.color}
        textColor="#ffffff"
      />

      { isDrawerAnimating && <Gallery images={images} UnitDrawer /> }

      <Buttons unitId={unitId} />

      <OpenHouse />

      <Box sx={{ px: 2.5, mt: { md: 3.5, xs: 2 } }}>
        <Box sx={{ mb: { md: 3.5, xs: 2 }, mx: "auto", maxWidth: 350 }}>
          <Highlights items={filterHighlights} />
        </Box>

        <Box sx={{ mb: { md: 5.5, xs: 2 }, mt: { md: 5, xs: 2 }, textAlign: "center" }}>
          <Price
            label={isRent ? `${ get(unit.data,'LejelejlighedsText') }` : "Kontantpris"}
            value={price}
          />
        </Box>

        <DrawerCTA unit={unit} />
      </Box>
      </Box>
      <PoweredBy />
    </Box>
  );
}

export default UnitDrawer;
