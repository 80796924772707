import { useNavigate }              from 'react-router-dom'
import { dropdownOptions as dropdownTranslation, options }                  from './Dropdown2.Data'
import { Portal }                   from 'components/Portal/Portal'
import { Backdrop }                 from 'styles/styles.backdrop'
import { AnimatePresence }          from 'framer-motion'
import { useBreak }                 from 'hooks/useBreak'
import { useTranslation }           from 'react-i18next'
import { Main, Option, Cell, Text } from './Dropdown2.Foldout.style'
import { getFocus, useAtom, useGet, useSet }      from 'state/jotai'
import { active_project, currentActiveProject, current_language }       from "state/store.global";
import { _bootstrap } from 'state/store.bootstrap'
import { useEffect, useState } from 'react'

export const Foldout = ({ state, setState, location }) => {
  const setActiveID  = useSet(active_project)
  const dropdownOption    = getFocus(_bootstrap, 'projectnames')
  const { pin, pid } = useGet(active_project)
  const currentLanguage = useAtom(current_language)[0];
  const isDesktop = useBreak('md_up')
  const navigate  = useNavigate();
  const setActiveProject = useAtom(currentActiveProject)[1];
  // const face      = options().findIndex( i => location?.toLocaleUpperCase() == i.URLSafe?.toLocaleUpperCase() )
  const [face, setFace]      = useState('')
  useEffect(()=> {
    if(Object.keys(dropdownOption).length && pid) {
     setFace(dropdownOption[currentLanguage]?.filter(d => d.PID === pid)[0]?.URLSafe)
    }
   }, [pid, currentLanguage])
  
  const { i18n:{language} } = useTranslation()
  
  const animation = {
    initial   : { transform: `translate(${isDesktop?'-50%':'-50%'},-500px)`},
    animate   : { transform: `translate(${isDesktop?'-50%':'-50%'},  59px)`},
    exit      : { transform: `translate(${isDesktop?'-50%':'-50%'},-500px)`},
    transition: { duration:0.2, ease:'linear' }
  }

  return (
    <>
      <Portal id={'Dropdown2Foldout'}>
        <AnimatePresence>
          { 
            state && ( 
              <>
                <Backdrop onClick={()=>{
                  setState(false)
                }}/>
                <Main clr={clr} {...animation}>
                {
                  dropdownOption[currentLanguage].map(({ path, Presentation, URLSafe, PID, PIN, available_Units }, key ) => {
                    const active = URLSafe == face ? true : false
                    const click = () => {
                      setActiveID({
                        pid: PID, 
                        pin: PIN})
                      navigate('/' + language + path)
                      setActiveProject(Presentation)
                      setTimeout(()=>{setState(false)},150)
                    } 
                    return (
                      <>
                        <Option key={ key } active={ active }>
                          <Cell onClick={ ()=>click(URLSafe) }>
                            <Text clr={ clr }>{ URLSafe }</Text>
                          </Cell>
                        </Option>
                      </>
                    )
                  })
                }
                </Main>
              </>
            )
          }
        </AnimatePresence>
      </Portal>
    </>
  )
}