import { useState, useEffect }              from 'react'
import { useLocation }           from 'react-router-dom'
import { useTranslation }        from 'react-i18next'
import { useBreak }              from 'hooks/useBreak'

import { Arrow }                 from './Dropdown2.Arrow'
import { Foldout }               from './Dropdown2.Foldout'
import { Main, Label, Value }    from './Dropdown2.style'
import { options }               from './Dropdown2.Data'
import { active_project, current_language }        from 'state/store.global'
import { getFocus, useAtom, useGet }      from 'state/jotai'
import { _project }              from 'state/store.projects'
import { _bootstrap } from 'state/store.bootstrap'

export const Dropdown = () => {
  
  const [state, setState]   = useState(false)
  const currentLanguage = useAtom(current_language)[0];
  const dropdownOptions       = getFocus(_bootstrap, 'projectnames');
  const { i18n:{language} } = useTranslation()
  
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isDesktop = useBreak('md_up')
  const location  = useLocation().pathname.replace('/'+language,'')
  // const face      = options().find( i => location == i.path )
  const [face, setFace]      = useState('')
  
  useEffect(()=> {
   if(Object.keys(dropdownOptions).length && pid) {
    setFace(dropdownOptions[currentLanguage].filter(d => d.PID === pid)[0]?.URLSafe)
   }
  }, [pid, currentLanguage])
  const click     = () => setState(!state)

  return (
    <>
      <Main onClick = {click}>
        { isDesktop && <Label style={{color: clr?.sub_text_color}}>{buttonsLabel?.filter( btn => btn.UILabelProperty === 'VælgVinkel')[0]?.Label}:</Label> }
        <Value style={{color:isDesktop?clr?.sub_text_color:clr?.alterntive_condition}}>{ face }</Value>
        <Arrow fill = {isDesktop?clr?.sub_text_color:clr?.alterntive_condition} dir = { state ? 'up' : 'down' }/>
      </Main>
      <Foldout state = {state} setState = {setState} location = {face}/>
    </>
  )
}