const palette = {
  primary: {
    main: "#976a4b",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#e2ddd8",
    contrastText: "#000000 ",
  },
  text: {
    primary: "#000000",
  },
  success: {
    main: "#0E912C",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
};

export default palette;
