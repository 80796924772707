import styled from 'styled-components/macro'
import { Icon } from 'assets/Icons_'
import { iso_free, iso_isrotating, active_project, vis_desktop_nav, vis_filter } from 'state/store.global'
import { getFocus, useAtom, useGet, useSet } from 'state/jotai'
import { useBreak } from 'hooks/useBreak'
import { BREAK_ } from 'constants'
import { _project } from 'state/store.projects'
import { close_drawer } from 'state/actions'
import { useEffect } from 'react'

export const Mode360 = () => {

  const rmode        = useGet(iso_free)
  const setRmode     = useSet(iso_free)
  const isrotate     = useGet(iso_isrotating)
  const setIsrotate  = useSet(iso_isrotating)
  const isMobile     = useBreak('md_dn')

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const [, setShowMenu] = useAtom(vis_desktop_nav);
  const [, setShowFilter] = useAtom(vis_filter);
  const closeDrawer = useAtom(close_drawer)[1];

  const click1 = () => setIsrotate(false)
  const click2 = () => {
    setRmode(true)
    setIsrotate(true)
  }
  useEffect(() => {
    if(rmode) {
      setShowFilter(false);
      setShowMenu(false);
      closeDrawer("detail");
    }
  }, [rmode]);
  return (
    <>
      <Box>
       { 
        rmode && isrotate ? <>
          <Icon click={click1} icon='pause' size={isMobile?'25':'32'} color={clr.sub_fade}/>
          {isMobile ? <Text>Pause</Text> : <Text>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'PauseRotation')[0]?.Label }</Text>}
        </> : <>
          <Icon click={click2} icon='play'  size={isMobile?'25':'32'} color={clr.sub_fade}/>
          {isMobile ? <Text>360&#x00B0;</Text> : <Text>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Afspil360rotation')[0]?.Label }</Text>}
        </>
       }
      </Box>
    </>
  )

}
const Text = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400px;  
  /* border: 1px solid black; */
`
const Box = styled.div`
  gap: 5px;
  cursor: pointer;
  padding: 5px 15px;
  z-index: 5;
  align-items: center;
  display: flex;
  ${ BREAK_.md_up } {
    position: absolute;
    top: 95px;
    right: 40px;
    padding:0;
  }
`