import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { active_project, vis_calculate_benefit } from 'state/store.global'
import { getFocus, useAtom, useGet } from 'state/jotai'
import { useHousingBenefit } from 'components/HousingBenefit/HousingBenefit.hook'
import { _project } from "state/store.projects";

function Properties({ groups, indent, isRent }) {
  const setBenefit  = useAtom(vis_calculate_benefit)[1]
  const output      = useHousingBenefit()

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
// console.log(groups,"group")
  return (
    <>
      {groups &&
        groups.map((group, i) => {
          const isAllValuePresent = group.data && group.data.every((data, i) => data.Value === "" || data.Value === null || data.Value === undefined || data.Value === "999.999" || data.Value === "999999" || data.Value === 0 || data.Value === "0")
          
          if(isAllValuePresent) return <></>
          
          return (
            <Box
              key={i}
              sx={{
                mb: 5,
                "&:last-child": {
                  mb: "0",
                },
              }}
            >
              <Box sx={{ fontWeight: 700, mb: "10px" }}>{group.title}</Box>
              <div>
                {group.data &&
                  group.data.map((data, i) => {
                    const label = data.Label;
                    const value =
                      data.Value === true
                        ? buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Ja')[0]?.Label
                        : data.Value === false
                        ? buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Nej')[0]?.Label
                        : data.Value;
                    const postfix = data.Postfix ?? "";
                    if(data.PropertyID === 113 && !isRent) return <></>
                    // if value is one of them then we don't have to show it in the frontend
                    if(value === "" || value === null || value === undefined || value === "999.999" || value === "999999" || value === 0 || value === "0") return null
                    
                    {/* if ( data.PropertyID !== 113 ) return ( */}
                    return (
                      <Box
                        key={i}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          px: indent ? 3 : 0,
                          py: 1.5,
                          borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
                          '&:last-child': {
                            borderBottom: 'none',
                          },
                        }}
                      >
                        <div>{label}</div>
                        {data.PropertyID !== 113 ? (
                          <div>
                            {value}
                            {postfix}
                          </div>
                        ) : (
                          <>
                          { 
                            output 
                              ? <div onClick={() => setBenefit(true)} style={{color:'unset',cursor:'pointer',textDecoration:'underline'}}>{output}</div> 
                              : <div onClick={() => setBenefit(true)} style={{color:'unset',cursor:'pointer',textDecoration:'underline'}}>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Beregn')[0]?.Label }</div>
                          }
                          </>
                        )}
                      </Box>
                    )
                  })}
              </div>
            </Box>
          );
        })}
    </>
  );
}

Properties.propTypes = {
  groups: PropTypes.array.isRequired,
  indent: PropTypes.bool,
};

export default Properties;
