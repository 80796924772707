import { Box, Grid, Typography } from "@mui/material";
import { STATICS } from "api/api";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useBreak } from "hooks/useBreak";
import { useActiveUnit } from "./hooks";

function Footer() {
  const { pin, pid } = useGet(active_project);
  const unit = useActiveUnit();
  const isRent = unit.isRent();
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
  const contactData = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? [];

  // Determine which contact data to show based on the `isRent` flag
  const contactToShow = isRent ? contactData[1] : contactData[0];
  
  // Custom hook to determine screen size breakpoints
  const isMobile = useBreak('md_dn');

  return (
    <div>
      <Box
        sx={{
          mt: 5,
          pb: 55,
          px: { md: 5, xs: 4 },
          py: { md: 0, xs: 2 },
          lineHeight: 1.4,
        }}
      >
        <Box sx={{ mb: 3 }}>
          {buttonsLabel?.filter(btn => btn.UILabelProperty === 'SidefodKontaktOverskrift')[0]?.Label}
        </Box>

        {contactToShow && (
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row', // Stack items vertically on mobile
              alignItems: isMobile ? 'flex-start' : 'center', // Align items to the start on mobile
              gap: 2, // Add some gap between the image and the address box
            }}
          >
            <Box 
              sx={{ 
                flex: 1, 
                mb: isMobile ? 2 : 0, // Add margin bottom only on mobile
              }}
            >
              {contactToShow?.Name && (
                <Box>{contactToShow.Name}</Box>
              )}
              {contactToShow?.AddressLine1 && (
                <Box>{contactToShow.AddressLine1}</Box>
              )}
              {contactToShow?.AddressLine2 && (
                <Box>{contactToShow.AddressLine2}</Box>
              )}
              {contactToShow?.Phone && (
                <Box>{contactToShow.Phone}</Box>
              )}
              {contactToShow?.Email && (
                <Typography
                  component="a"
                  href={`mailto:${contactToShow.Email}`}
                >
                  {contactToShow.Email}
                </Typography>
              )}
            </Box>
            <Box 
              sx={{ 
                width: '120px', // Fixed width for the image
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: isMobile ? 'flex-start' : 'center', // Align image to the start on mobile
              }}
            >
              {contactToShow?.ImageUrl && (
                <img
                  src={`${STATICS}${contactToShow.ImageUrl}`}
                  alt="footer_logo"
                  style={{ width: '100%', height: 'auto' }} // Ensure image scaling within the box
                />
              )}
            </Box>
          </Box>
        )}

        <div style={{ height: 80 }} />
      </Box>
    </div>
  );
}

export default Footer;
